import React from "react";

export const EstaremosDeVuelta = () => {
    return (
        <div
            className={'flex flex-col items-center justify-center h-screen w-screen'}
        >
            <h1
                className={'text-4xl text-center'}
            >
                Estaremos de vuelta pronto
            </h1>
            <p
                className={'text-lg text-center'}
            >
                NARA retomará actividades terminando el periodo electoral.
            </p>
        </div>
    );
}
