import {AppBar, Toolbar, Typography} from "@mui/material";
import LogoMC from "../Assets/Images/logo-mc.svg";
import {appColors} from "../Utils/AppColors";
import React from "react";

export const HeaderNara = () => {
    return (
        <AppBar
            elevation={3}
            position={'fixed'}
            className={`!bg-orange-500`}
        >
            <Toolbar
                className={'flex justify-between'}
            >
                <img
                    src={LogoMC}
                    alt={"Logo MC"}
                    style={{
                        height: 50,
                        width: "auto",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                />

                <Typography
                    style={{
                        color: appColors.white,
                        fontWeight: 300,
                        letterSpacing: 2,
                    }}
                >
                    Nara<br/>
                    by Lab MC
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
