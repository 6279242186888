import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material";
import {themeApp} from "./Utils/Theming";
import {Narav2} from "./Pages/Narav2";
import {GuestLayout} from "./Layouts/GuestLayout";
import {EstaremosDeVuelta} from "./Pages/EstaremosDeVuelta";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={themeApp}>
            <GuestLayout>
                <EstaremosDeVuelta />
            </GuestLayout>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
