import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {ClearOutlined, Inventory2Outlined, SendOutlined} from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {EmptyChatAnimation} from "../Assets/Lottie/EmptyChatAnimation.jsx";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Swal from "sweetalert2";

import LogoMC from "../Assets/Images/logo-mc.svg";
import {appColors} from "../Utils/AppColors";
import Video002 from "../Assets/Videos/video-005.mp4";

const TextFieldChatBot = styled(TextField)(() => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#9A761C77',
        },
        '&:hover fieldset': {
            borderColor: appColors.primary + 'AA',
        },
        '&.Mui-focused fieldset': {
            borderColor: appColors.primary + 'DD',
        },
        '& input': {
            color: appColors.primary,
        }
    },
    '& .MuiInputLabel-root': {
        color: appColors.primary,
        backgroundColor: 'white',
    },
    // Label focus
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'transparent',
        backgroundColor: 'transparent',
    },
    // Label shrink
    '& .MuiInputLabel-shrink': {
        color: 'transparent',
        backgroundColor: 'transparent',
    },
}));

const TextFieldChatBotAutocomplete = styled(TextField)(() => ({
    marginBottom: '0.5rem',
    color: 'white',
    minHeight: '50px !important',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#EFEFEF',
            borderRadius: '10px',
            minHeight: '50px !important',
        },
        '&:hover fieldset': {
            borderColor: '#EFEFEF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#EFEFEF',
            color: 'white',
        },
        '& input': {
            color: '#EFEFEF !important',
            minHeight: '50px !important',
        },
        '& .MuiSvgIcon-root': {
            color: 'white !important',
        }
    },
    '& .MuiInputLabel-root': {
        color: 'white',
        backgroundColor: 'transparent',
        marginTop: '-0.5rem',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        marginTop: 0,
    },
    // Label shrink
    '& .MuiInputLabel-shrink': {
        marginTop: 0,
    },
    '& .MuiInputAdornment-root': {
        color: 'white !important',
    },
}));

export const Narav2 = () => {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    const profile = 'administrador';

    const [botText, setBotText] = useState('');
    const [loadingBot, setLoadingBot] = useState(false);
    const [botAnswer, setBotAnswer] = useState('');
    const [errorOpenAI, setErrorOpenAI] = useState('');

    const [search, setSearch] = useState('');
    const [listaPacientes, setListaPacientes] = useState([]);
    const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
    const [paginatorData, setPaginatorData] = useState({page: 1, totalPages: 1});
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [labsPaciente, setLabsPaciente] = useState([]);
    const [labsOk, setLabsOk] = useState(false);
    const [loaderLabs, setLoaderLabs] = useState(false);

    const [chatMessages, setChatMessages] = useState([]);

    // Ref container for chat messages
    const chatMessagesRef = useRef(null);

    // Comments
    const [comments, setComments] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [loadingComments, setLoadingComments] = useState(false);

    const scrollToBottomChats = () => {
        // Check if chat messages ref is not null
        if (!chatMessagesRef.current) {
            return;
        }

        // Scroll to bottom of chat messages
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }

    useEffect(() => {
        scrollToBottomChats();
    }, [chatMessages]);

    const faqs = {
        administrador: [
            "¿Cuál es el principal compromiso de movimiento ciudadano?",
            "¿Cómo me puedes ayudar?",
            "Genérame un reporte de los principales ideales y compromisos de MC.",
            "¿Qué es Voces del Futuro?",
            "¿Cómo se resguardan mis propuestas en Voces del Futuro?",
            "Quisiera crear una propuesta para que sea revisada por el partido, ¿cómo puedo hacerlo?",
            "¿Cómo se que mis propuestas serán tomadas en cuenta por el partido?",
        ],
    };

    const sendMessage = (textParam = botText) => {
        setBotAnswer("Enviando...");
        setErrorOpenAI('')
        setLoadingBot(true);

        const formData = new FormData();
        formData.append('query', textParam);

        if (pacienteSeleccionado && labsOk) {
            formData.append('paciente', JSON.stringify(pacienteSeleccionado));
            formData.append('labs', JSON.stringify(labsPaciente));
            // formData.append('id_perfil', pacienteSeleccionado['id']);
            formData.append('id_perfil', 1);
        }

        let url = 'https://nara-rag.blackrock-483c688e.eastus.azurecontainerapps.io/api/v2/llm/query';
        let url0 = 'http://127.0.0.1:5000/api/v2/llm/query';

        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(async response => {
                if (response.status === 201 || response.status === 200) {
                    setBotAnswer(response?.['data'] ?? "No se pudo obtener respuesta");
                    setLoadingBot(false);

                    let messagesTmp = chatMessages;
                    messagesTmp.push({
                        role: 'user',
                        message: textParam,
                    });
                    messagesTmp.push({
                        role: 'nara',
                        message: response?.['data'] ?? "No se pudo obtener respuesta",
                    });
                    setChatMessages(messagesTmp);

                    setTimeout(() => {
                        scrollToBottomChats();
                    }, 300);
                } else {
                    setBotAnswer('');
                    setErrorOpenAI("Error: " + response?.data || 'Error al enviar el mensaje');
                    setLoadingBot(false);
                }
            })
            .catch(async error => {
                setBotAnswer('');
                setErrorOpenAI("Error: " + error);
                setLoadingBot(false);
            });
    }

    const sendComments = () => {
        setLoadingComments(true);

        const formData = new FormData();
        formData.append('comentario', comments);
        formData.append('pregunta', botText);
        formData.append('respuesta', botAnswer);

        axios.post("https://dashboard.ciudadanosenmovimiento.org/api/nara/enviar/comentario", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(async data => {
                setShowComments(false);

                await Swal.fire({
                    title: "Comentario enviado",
                    text: data?.['message'] ?? "Gracias por tu comentario, esto nos ayuda a mejorar nuestro servicio.",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                setComments('');
                setLoadingComments(false);
            })
            .catch(async error => {
                await Swal.fire({
                    title: "Error",
                    text: "No se pudo enviar el comentario: " + error?.message ?? error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });

                setLoadingComments(false);
            });
    }

    return (
        <Grid2
            container
            className={'!w-full flex-1'}
        >
            <Dialog
                open={showComments}
                onClose={() => setShowComments(false)}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle>
                    <Typography
                        variant="h5"
                        style={{
                            color: appColors.primary,
                            fontWeight: '700',
                        }}
                    >
                        Enviar comentarios
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <TextField
                        variant="outlined"
                        placeholder="Escribe aquí tus comentarios"
                        value={comments}
                        fullWidth
                        onChange={(e) => setComments(e.target.value)}
                        style={{
                            marginBottom: '1rem',
                        }}
                        multiline
                        rows={4}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!comments || loadingComments}
                        style={{
                            borderRadius: '10px',
                            height: 50,
                        }}
                        onClick={() => {
                            sendComments();
                        }}
                    >
                        Enviar comentarios
                        <Inventory2Outlined style={{marginLeft: 10}}/>
                    </Button>
                </DialogContent>
            </Dialog>

            <Grid2
                item
                sm={12}
                md={4}
            >
                <Stack
                    direction="column"
                    width={'100%'}
                    justifyContent={'start'}
                    alignItems={'start'}
                    padding={0}
                    backgroundColor={appColors.primary}
                    className={'!px-2 !py-4'}
                    style={{
                        height: sm ? '700px' : 'calc(100vh - 70px)',
                        position: 'relative',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >

                    <div
                        className={'w-full h-full absolute top-0 left-0 bg-orange-500 backdrop-blur-lg'}
                    />

                    {
                        listaPacientes?.length > 0 && profile !== 'paciente' ?
                            <Stack
                                sx={{
                                    marginTop: '1rem',
                                    marginBottom: sm ? '1rem' : '0.0rem',
                                    zIndex: 2,
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: 'white',
                                        fontWeight: '500',
                                        marginBottom: '0.8rem',
                                    }}
                                >
                                    Seleccione un paciente o busque por nombre si desea agregarlo a la conversación.
                                </Typography>

                                <Autocomplete
                                    multiple={false}
                                    id="pacientes-autocomplete"
                                    fullWidth
                                    options={listaPacientes} // Asegúrate de que listaCandidatos es un arreglo de objetos como el que proporcionaste
                                    getOptionLabel={(option) => `${option['nombre']} ${option['primer_ap']}` || ''}
                                    onChange={(event, newValue) => {
                                        setLabsOk(false);
                                        setPacienteSeleccionado(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextFieldChatBotAutocomplete
                                            {...params}
                                            variant="outlined"
                                            placeholder="Seleccione un paciente"
                                        />
                                    )}
                                />
                            </Stack>
                            :
                            loaderStatus ?
                                <div
                                    style={{
                                        backgroundColor: '#D9DDE7',
                                        backdropFilter: 'blur(10px)',
                                        borderRadius: '10px',
                                        fontWeight: '400',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '1.0rem',
                                        animation: 'ease-in-out 1s infinite alternate',
                                        color: '#6B7280',
                                        padding: '1rem',
                                        zIndex: 2,
                                    }}
                                >
                                    {
                                        'Cargando datos de candidatos, por favor espere...'
                                    }
                                    <LinearProgress
                                        variant={'query'}
                                        sx={{
                                            height: '1px',
                                            color: '#6B7280 !important',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: '#6B7280 !important',
                                            }
                                        }}
                                        style={{
                                            marginTop: '1.0rem',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </div>
                                :
                                null
                    }

                    <div
                        className={'w-full text-2xl font-bold text-white z-[2]'}
                    >
                        Preguntas frecuentes
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            flexWrap: 'wrap',
                            maxWidth: '100%',
                            gap: '0.8rem',
                            marginTop: '2rem',
                            marginBottom: sm ? '20px' : 'auto',
                            minHeight: '300px',
                            overflowY: 'auto',
                            zIndex: 2,
                        }}
                    >
                        {
                            faqs[profile] ?
                                faqs[profile].map((faq, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'w-full px-2 py-4 bg-white text-slate-500 font-normal rounded-lg cursor-pointer hover:bg-orange-600 hover:text-white' +
                                                ' border border-orange-500 hover:border-white'}
                                            onClick={() => {
                                                setBotText(faq);
                                                sendMessage(faq);
                                            }}
                                        >
                                            {faq}
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>

                    <img
                        src={LogoMC}
                        alt="Logo Movimiento Ciudadano"
                        className={'text-white mx-auto mt-[10px] z-[2]'}
                    />
                </Stack>
            </Grid2>

            <Grid2
                item
                xs={12}
                md={8}
                style={{padding: '0'}}
            >
                <Stack
                    direction="column"
                    width={'100%'}
                    height={'100%'}
                    justifyContent={'start'}
                    alignItems={'start'}
                    padding={'1rem 1.5rem'}
                    position="relative"
                    sx={{
                        height: sm ? 'auto' : 'calc(100vh - 70px)',
                        position: 'relative',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#cdcdcd',
                            border: '2px solid ' + appColors.black,
                            borderRadius: '10px',
                            fontWeight: '500',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            padding: '0.5rem',
                            marginBottom: '1rem',
                            color: "#555",
                        }}
                    >
                        Este herramienta se encuentra en fase de pruebas, por favor si encuentras algún error,
                        no dudes en enviar tus <b>comentarios</b>.
                    </div>

                    <Stack
                        direction="row"
                        fullWidth
                        padding={0}
                        justifyContent={'space-between'}
                    >
                        <h2
                            style={{
                                color: 'black',
                                fontWeight: '700',
                                marginBottom: '1rem',
                                letterSpacing: '1px',
                                textAlign: sm ? 'center' : 'left',
                            }}
                            className={`text-xl font-bold ${sm ? 'text-center' : 'text-left'}`}
                        >
                            ¡Hola! soy <span className={'text-orange-500'}>NARA</span>, ¿en qué puedo ayudarte?
                        </h2>
                    </Stack>

                    {
                        (chatMessages.length === 0) ?
                            <Stack
                                direction="column"
                                justifyContent={'center'}
                                alignItems={'center'}
                                width={'100%'}
                                minHeight={'600px'}
                                style={{
                                    flex: 1,
                                    marginBottom: sm ? '5rem' : '4rem',
                                    display: 'flex',
                                }}
                            >
                                <EmptyChatAnimation/>
                                <h2
                                    style={{
                                        color: 'black',
                                        fontWeight: '800',
                                        marginBottom: '0.8rem',
                                        letterSpacing: '2px',
                                        textAlign: 'center',
                                    }}
                                >
                                    ¡Hola! Soy <span className={'text-orange-500'}>NARA</span>, el asistente virtual de Movimiento Ciudadano.
                                </h2>
                            </Stack>
                            :
                            <div
                                ref={chatMessagesRef}
                                style={{
                                    flex: 1,
                                    width: '100%',
                                    height: sm ? '700px' : 'auto',
                                    maxHeight: sm ? '700px' : 'auto',
                                    overflowY: 'scroll',
                                    marginBottom: '3.5rem',
                                    scrollBehavior: 'smooth',
                                    paddingBottom: sm ? '5rem' : '4rem',
                                }}
                            >
                                {chatMessages.map((message, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: message.role === 'user' ? 'flex-end' : message.role === 'nara' ? 'flex-start' : 'center',
                                            marginBottom: message.role === 'user' ? '0.5rem' : '2.5rem',
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: sm ? '95%' : '75%', // o el porcentaje que prefieras
                                                padding: '0.8rem',
                                                borderRadius: '20px', // esto crea la forma de la burbuja
                                                fontWeight: '500',
                                                backgroundColor: message.role === 'user' ? 'rgba(206,150,29,0.27)' : message.role === 'nara' ? 'rgba(231,212,88,0.27)' : '#48A296',
                                                color: message.role === 'user' ? appColors.primary : message.role === 'nara' ? '#7a7a7a' : '#ffffff',
                                                textAlign: 'left',
                                                overflowWrap: 'break-word',
                                            }}
                                        >
                                            <Markdown
                                                remarkPlugins={[remarkGfm]}
                                            >
                                                {message.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                ))}
                            </div>
                    }

                    <div
                        style={{
                            width: 'calc(100% - 40px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: 15,
                            left: 20,
                            right: 20,
                            backgroundColor: 'white',
                        }}
                    >
                        <Grid2
                            container
                            spacing={2}
                        >
                            <Grid2
                                item xs={12}
                            >
                                <TextFieldChatBot
                                    variant="outlined"
                                    placeholder="Pregúntame algo..."
                                    value={botText}
                                    fullWidth
                                    onChange={(e) => setBotText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            sendMessage();
                                        }
                                        if (e.key === 'Escape') {
                                            setBotText('');
                                            e.preventDefault();
                                        }
                                    }}
                                    style={{
                                        flex: 1,
                                    }}
                                    InputProps={{
                                        endAdornment: botText ? (
                                            <InputAdornment position="end">
                                                <ClearOutlined
                                                    style={{cursor: 'pointer', color: 'red'}}
                                                    onClick={() => setBotText('')}
                                                />
                                            </InputAdornment>
                                        ) : null,
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                style={{marginRight: '8px'}}
                                            >
                                                <EmptyChatAnimation
                                                    height={35}
                                                    width={35}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid2>

                            <Grid2
                                item xs={6}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={!botText || loadingBot}
                                    style={{
                                        borderRadius: '10px',
                                        height: 50,
                                    }}
                                    onClick={() => {
                                        sendMessage();
                                    }}
                                >
                                    Preguntar&nbsp;
                                    <SendOutlined/>
                                </Button>
                            </Grid2>

                            <Grid2
                                item xs={6}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled={loadingBot || chatMessages?.length === 0}
                                    sx={{
                                        backgroundColor: "rgb(68,154,28)",
                                    }}
                                    style={{
                                        borderRadius: '10px',
                                        height: 50,
                                    }}
                                    onClick={() => {
                                        setShowComments(true);
                                    }}
                                >
                                    Enviar comentarios
                                    <Inventory2Outlined style={{marginLeft: 10}}/>
                                </Button>
                            </Grid2>
                        </Grid2>

                        {
                            loadingBot ?
                                <div
                                    style={{
                                        marginTop: '1rem',
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '10px',
                                        fontWeight: '500',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '0.8rem',
                                        animation: 'ease-in-out 1s infinite alternate',
                                    }}
                                >
                                    Procesando respuesta con IA, espere un momento...
                                    <LinearProgress
                                        style={{
                                            marginTop: '0.3rem',
                                            borderRadius: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                </div>
                                : null
                        }

                        {
                            errorOpenAI !== '' ?
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '1rem',
                                        fontWeight: '500',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '0.8rem',
                                        animation: 'ease-in-out 1s infinite alternate',
                                    }}
                                >
                                    {errorOpenAI}
                                </div>
                                : null
                        }
                    </div>
                </Stack>
            </Grid2>
        </Grid2>
    )
}
