import React from "react";
import {HeaderNara} from "../Components/HeaderNara";
import PropTypes from 'prop-types';
import {Container, useMediaQuery, useTheme} from "@mui/material";

export const GuestLayout = ({children}) => {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div
            className={`w-screen flex flex-col ${sm ? 'h-screen' : 'min-h-screen'}`}
        >
            <HeaderNara/>

            <Container
                maxWidth={false}
                className={'!p-0 !mt-[70px] !flex flex-1'}
            >
                {children}
            </Container>
        </div>
    );
}

GuestLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
